
import { defineComponent } from 'vue';
import FormInput from '@/components/ui/form/FormInput.vue';
import countriesService from '@/services/countries.service';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import customersService from '@/services/customers.service';
import { Country } from '@/model/country';
import { AppMutation } from '@/store/app/app.mutation';
import { NotificationType } from '@/store/app/notification/notification-type';
import * as yup from 'yup';
import { useField, useForm } from 'vee-validate';
import { Supplier } from "@/model/supplier";
import suppliersService from "@/services/suppliers.service";
import VueMultiselect from 'vue-multiselect';
import { Customer } from "@/model/customer";
import { AxiosPromise } from "axios";
import { string } from 'yup';
import ContentLoader from '@/components/ui/ContentLoader.vue';

export default defineComponent({
  name: "ClientCreate",
  components: {ContentLoader, OutlineButton, FormInput, VueMultiselect},
  setup() {
    const schema = yup.object({
      name: yup.string().required('Name is required'),
      selectedSuppliers: yup.array().test({
        message: 'Select at least one supplier',
        test: array => !!array && !!array.length
      }),
      country: yup.object().shape({
        id: string()
      }).required('Country is required').typeError('Country is required')
    })

    useForm({
      validationSchema: schema,
    });

    const {value: name, errorMessage: nameError, meta: nameMeta} = useField('name');
    const {
      value: selectedSuppliers,
      errorMessage: selectedSuppliersError,
      meta: selectedSuppliersMeta
    } = useField('selectedSuppliers');
    const {value: country, errorMessage: countryError, meta: countryMeta} = useField('country');


    return {
      name,
      nameError,
      nameMeta,
      country,
      countryError,
      countryMeta,
      selectedSuppliers,
      selectedSuppliersError,
      selectedSuppliersMeta
    };
  },
  data() {
    return {
      taxNumber: '',
      website: '',
      phone: '',
      contactName: '',
      contactPosition: '',
      contactEmail: '',
      address: '',
      zipCode: '',
      city: '',
      notes: '',
      countries: [] as Country[],
      suppliers: [] as Supplier[],
      customers: [] as Customer[],
      isProcessing: false
    }
  },
  mounted(): void {
    this.isProcessing = true;

    Promise.all([
      countriesService
          .getCountries()
          .then(({data}) => this.countries = data),
      suppliersService
          .getAll()
          .then(({data}) => this.suppliers = data)
    ]);

    this.isProcessing = false;
  },
  methods: {
    handleCancelClick(): void {
      this.$router.push({name: 'customers.list'})
    },
    handleSaveButton(): void {
      this.saveCustomer()
          .then(({data}) => this.$router.push({name: 'customers.list', params: {clientId: data.id}}));
    },
    handleProceedButton(): void {
      this.saveCustomer()
          .then(({data}) => this.$router.push({name: 'proposal.form', params: {clientId: data.id}}));
    },
    saveCustomer(): AxiosPromise<Customer> {
      this.isProcessing = true;

      return customersService.save({
        companyName: this.name as string,
        taxId: this.taxNumber,
        website: this.website,
        contactName: this.contactName,
        contactPosition: this.contactPosition,
        contactEmail: this.contactEmail,
        phone: this.phone,
        address: this.address,
        note: {
          content: this.notes,
          label: 'test label'
        },
        countryId: (this.country as Country)?.id,
        suppliers: (this.selectedSuppliers as Supplier[]).map((supplier: Supplier) => supplier.id)
      })
          .then(response => {
            this.$store.commit(AppMutation.ADD_NOTIFICATION, {
              type: NotificationType.SUCCESS,
              title: 'general.notifications.success',
              content: 'customer.create.notifications.save_success'
            })

            return response;
          })
          .finally(() => this.isProcessing = false);
    }
  }
})
