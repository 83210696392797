
import { defineComponent, PropType } from 'vue';
import { Customer } from '@/model/customer';
import Modal from '@/components/ui/Modal.vue';
import CustomerDetailsModal from '@/components/customer/CustomerDetailsModal.vue';
import CustomerRowActions from '@/components/customer/list/CustomerRowActions.vue';

export default defineComponent({
  name: "CustomerRow",
  components: {CustomerDetailsModal, Modal, CustomerRowActions},
  props: {
    customer: {
      required: true,
      type: Object as PropType<Customer>
    }
  },
  data() {
    return {
      showClientModal: false
    }
  }
})
