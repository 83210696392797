
import { defineComponent } from 'vue';

export default defineComponent({
  name: "FormInput",
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: String
    },
    id: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false
    },
    error: {
      type: String,
      required: false
    }
  },
  computed: {
    translatedPlaceholder(): string {
      if (!this.placeholder) {
        return '';
      }

      return this.$t(this.placeholder);
    },
    value: {
      get(): undefined | string {
        return this.modelValue;
      },
      set(value: string): void {
        this.$emit('update:modelValue', value);
      }
    }
  }
})
