
import { defineComponent, PropType } from 'vue';
import DropdownMenu, { Action } from '@/components/ui/DropdownMenu.vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import { Customer } from '@/model/customer';
import { ProposalExportType } from '@/model/proposal-export-type';
import customersService from '@/services/customers.service';
import { AxiosPromise } from "axios";

// TODO: ACTIONS

export default defineComponent({
  name: "CustomerRowActions",
  components: {OutlineButton, DropdownMenu},
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true
    }
  },
  computed: {
    actions(): Action[] {
          return [
            {
              id: 'send',
              icon: 'send-action',
              label: 'customer.list.actions.export_xlsx',
              separate: false,
              type: undefined,
              action: (): AxiosPromise<string> => {
                return customersService.export(this.customer.id, ProposalExportType.XLS_CUSTOMER)
                  .then(response => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.setAttribute('download', `All proposals for customer - ${this.customer.id}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    return response;
                  })
              }
            }
          ];
    }
  },
})
