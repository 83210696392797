
import { defineComponent } from 'vue';
import SearchInput from "@/components/ui/form/SearchInput.vue";
import ContentLoader from "@/components/ui/ContentLoader.vue";
import { Customer } from "@/model/customer";
import customersService from "@/services/customers.service";
import CustomerRow from '@/components/customer/list/CustomerRow.vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';

export default defineComponent({
  name: "ClientsList",
  components: {OutlineButton, CustomerRow, ContentLoader, SearchInput},
  data() {
    return {
      search: '',
      customers: [] as Customer[],
      isCustomersProcessing: false
    }
  },
  mounted(): void {
    this.isCustomersProcessing = true;

    customersService
        .getList(undefined, undefined)
        .then(({data}) => this.customers = data)
        .finally(() => this.isCustomersProcessing = false);
  },
  computed: {
    filteredCustomers(): Customer[] {
      if (!this.search) {
        return this.customers;
      }

      return this.customers.filter(
          (customer: Customer) => customer.companyName.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  }
})
