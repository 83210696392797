import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-125c69cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "client-create" }
const _hoisted_2 = { class: "client-create__header" }
const _hoisted_3 = { class: "client-create__section" }
const _hoisted_4 = { class: "client-create__info" }
const _hoisted_5 = { class: "client-create__section__title" }
const _hoisted_6 = { class: "form" }
const _hoisted_7 = { class: "form__section" }
const _hoisted_8 = { class: "form__section__caption" }
const _hoisted_9 = { class: "form__section__group flex flex-row flex-wrap -mx-5" }
const _hoisted_10 = { class: "w-1/2 px-5" }
const _hoisted_11 = { class: "w-1/2 px-5" }
const _hoisted_12 = { class: "w-1/2 px-5 mt-6" }
const _hoisted_13 = { class: "w-1/2 px-5 mt-6" }
const _hoisted_14 = { class: "w-1/2 px-5 mt-6" }
const _hoisted_15 = { class: "form__section mt-12" }
const _hoisted_16 = { class: "form__section__caption" }
const _hoisted_17 = { class: "form__section__group flex flex-row flex-wrap -mx-5" }
const _hoisted_18 = { class: "w-1/2 px-5" }
const _hoisted_19 = { class: "w-1/2 px-5" }
const _hoisted_20 = { class: "w-1/2 px-5 mt-6" }
const _hoisted_21 = { class: "form__section mt-12" }
const _hoisted_22 = { class: "form__section__caption" }
const _hoisted_23 = { class: "form__section__group flex flex-row flex-wrap -mx-5" }
const _hoisted_24 = { class: "w-1/2 px-5" }
const _hoisted_25 = { class: "w-1/2 px-5" }
const _hoisted_26 = { class: "w-1/2 px-5 mt-6" }
const _hoisted_27 = { class: "w-1/2 px-5 mt-6" }
const _hoisted_28 = { class: "w-1/2 px-5 mt-6" }
const _hoisted_29 = { class: "mt-10 flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_loader = _resolveComponent("content-loader")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_vue_multiselect = _resolveComponent("vue-multiselect")!
  const _component_outline_button = _resolveComponent("outline-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isProcessing)
      ? (_openBlock(), _createBlock(_component_content_loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('customer.create.header')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t('customer.create.info.title')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('customer.create.info.form.caption.general')), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.name",
                  id: "name",
                  modelValue: _ctx.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                  required: true,
                  error: _ctx.nameError
                }, null, 8, ["modelValue", "error"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.tax",
                  id: "tax",
                  modelValue: _ctx.taxNumber,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.taxNumber) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.website",
                  id: "website",
                  modelValue: _ctx.website,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.website) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.phone",
                  id: "phone",
                  modelValue: _ctx.phone,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phone) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.suppliers",
                  id: "suppliers",
                  required: true,
                  error: _ctx.selectedSuppliersError
                }, {
                  control: _withCtx(() => [
                    _createVNode(_component_vue_multiselect, {
                      options: _ctx.suppliers,
                      id: "suppliers",
                      label: "fullName",
                      modelValue: _ctx.selectedSuppliers,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedSuppliers) = $event)),
                      "close-on-select": false,
                      multiple: true,
                      "track-by": "id",
                      "preselect-first": true
                    }, null, 8, ["options", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["error"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('customer.create.info.form.caption.contact')), 1),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.contact_name",
                  id: "contact-name",
                  modelValue: _ctx.contactName,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contactName) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.contact_position",
                  id: "contact-position",
                  modelValue: _ctx.contactPosition,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.contactPosition) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.contact_email",
                  id: "contact-email",
                  modelValue: _ctx.contactEmail,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.contactEmail) = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('customer.create.info.form.caption.address')), 1),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.country",
                  id: "country",
                  required: true,
                  error: _ctx.countryError
                }, {
                  control: _withCtx(() => [
                    _createVNode(_component_vue_multiselect, {
                      options: _ctx.countries,
                      id: "country",
                      label: "name",
                      modelValue: _ctx.country,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.country) = $event)),
                      "track-by": "id",
                      "preselect-first": true
                    }, null, 8, ["options", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["error"])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.address",
                  id: "address",
                  modelValue: _ctx.address,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.address) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.zip_code",
                  id: "zip-code",
                  modelValue: _ctx.zipCode,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.zipCode) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.city",
                  id: "city",
                  modelValue: _ctx.city,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.city) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_form_input, {
                  label: "customer.create.info.form.label.notes",
                  id: "notes",
                  modelValue: _ctx.notes,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.notes) = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_29, [
      _createVNode(_component_outline_button, {
        onClick: _ctx.handleSaveButton,
        disabled: !_ctx.nameMeta.valid || !_ctx.selectedSuppliersMeta.valid || !_ctx.countryMeta.valid
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('customer.create.buttons.save')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"]),
      _createVNode(_component_outline_button, {
        onClick: _ctx.handleProceedButton,
        disabled: !_ctx.nameMeta.valid || !_ctx.selectedSuppliersMeta.valid || !_ctx.countryMeta.valid,
        class: "ml-6"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('customer.create.buttons.proceed')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"]),
      _createVNode(_component_outline_button, {
        type: "outline",
        class: "ml-6",
        onClick: _ctx.handleCancelClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}